<template>
    <div>
        <div
            class="bg-gray-100 overflow-hidden h-full"
            :class="{ 'opacity-50': toggleModal || toggleProof }"
        >
            <div class="m-8 pb-8 bg-white rounded-xl shadow-xl">
                <h2
                    class="max-w-6xl lg:-mx-5 pt-5 mx-auto mt-8 px-4 text-lg leading-6 text-left font-medium text-gray-900 sm:px-6 lg:px-8"
                >
                    {{ $t("approvals.pending") }}
                </h2>
                <!-- Activity table (small breakopoint and up) -->
                <div class="block">
                    <div class="mx-auto">
                        <div class="flex flex-col mt-2">
                            <div
                                class="align-middle min-w-full overflow-x-auto border-b overflow-x-scroll"
                            >
                                <table
                                    class="table-auto min-w-full divide-y divide-gray-200"
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                class="whitespace-nowrap pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                            >
                                                {{ $t("thedesk.companyName") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                            >
                                                {{ $t("thedesk.transfer_amt") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm font-medium text-gray-500 tracking-wider border-r"
                                            >
                                                {{ $t("thedesk.payment_date") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                            >
                                                {{ $t("thedesk.ref_no") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm font-medium text-gray-500 tracking-wider border-r"
                                            >
                                                {{ $t("thedesk.payer_acc") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm font-medium text-gray-500 tracking-wider border-r"
                                            >
                                                {{
                                                    $t("thedesk.transfer_date")
                                                }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wider"
                                            >
                                                {{ $t("approvals.actions") }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        class="bg-white divide-y divide-gray-200"
                                    >
                                        <tr
                                            class="bg-white text-left font-medium text-tiny"
                                            v-for="(record, index) in this
                                                .pendingList.list"
                                            :key="index"
                                        >
                                            <td
                                                class="border-r whitespace-nowrap px-4 py-3"
                                            >
                                                {{ record.enterprise }}
                                            </td>
                                            <td class="border-r px-4">
                                                {{ record.credit_amt }}
                                            </td>
                                            <td class="border-r px-4">
                                                {{ record.payment_time }}
                                            </td>
                                            <td class="border-r px-4">
                                                {{ record.reference_no }}
                                            </td>
                                            <td class="border-r px-4">
                                                {{ record.payer_acc }}
                                            </td>
                                            <td class="border-r px-4">
                                                {{ record.receive_time }}
                                            </td>
                                            <td
                                                class="whitespace-nowrap text-center"
                                            >
                                                <span
                                                    class="relative mx-2 lg:mx-0 z-0 inline-flex rounded-md"
                                                >
                                                    <button
                                                        @click="
                                                            checkMessage(
                                                                index,
                                                                'pending',
                                                                record.pending_id
                                                            )
                                                        "
                                                        type="button"
                                                        class="relative inline-flex items-center px-2 my-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                    >
                                                        <svg
                                                            class="h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                                            />
                                                        </svg>
                                                    </button>
                                                    <button
                                                        @click="
                                                            handleApprovals(
                                                                record.pending_id,
                                                                'approve'
                                                            )
                                                        "
                                                        type="button"
                                                        class="-ml-px relative inline-flex items-center px-2 my-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                    >
                                                        ✓
                                                    </button>
                                                    <button
                                                        @click="
                                                            handleApprovals(
                                                                record.pending_id,
                                                                'reject'
                                                            )
                                                        "
                                                        type="button"
                                                        :class="{
                                                            'rounded-r-md': !record.attachment
                                                        }"
                                                        class="-ml-px relative inline-flex items-center px-2 my-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                    >
                                                        ×
                                                    </button>
                                                    <button
                                                        v-if="record.attachment"
                                                        @click="
                                                            getAdvice(
                                                                record.pending_id,
                                                                record.enterprise_id
                                                            )
                                                        "
                                                        type="button"
                                                        class="-ml-px relative inline-flex items-center px-2 my-1 border rounded-r-md border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                    >
                                                        Check receipt
                                                    </button>
                                                    <!--                                                <button-->
                                                    <!--                                                    type="button"-->
                                                    <!--                                                    class="-ml-px relative inline-flex items-center px-2 my-1 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"-->
                                                    <!--                                                >-->
                                                    <!--                                                    …-->
                                                    <!--                                                </button>-->
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="this.pendingList.total_page >= 1"
                        class="mt-8 flex flex-wrap justify-center items-center"
                    >
                        <button
                            @click="
                                currentPage > 1
                                    ? [
                                          (currentPage -= 1),
                                          this.getPendingList()
                                      ]
                                    : ''
                            "
                            type="button"
                            class="px-6 py-1 border rounded-md"
                        >
                            {{ $t("button.prev_pg") }}
                        </button>
                        <span class="mx-4">
                            {{ $t("page1") }} {{ currentPage }}/{{
                                this.pendingList.total_page
                            }}
                            {{ $t("page2") }}</span
                        >
                        <button
                            @click="
                                currentPage < this.pendingList.total_page
                                    ? [
                                          (currentPage += 1),
                                          this.getPendingList()
                                      ]
                                    : ''
                            "
                            type="button"
                            class="px-6 py-1 border rounded-md"
                        >
                            {{ $t("button.next_pg") }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="m-8 pb-8 bg-white rounded-xl shadow-xl">
                <h2
                    class="max-w-6xl lg:-mx-5 pt-5 mx-auto mt-8 px-4 text-lg leading-6 text-left font-medium text-gray-900 sm:px-6 lg:px-8"
                >
                    {{ $t("approvals.resolved") }}
                </h2>

                <select
                    name="recordsfilter"
                    @change="updateFilter($event.target.value)"
                    class="block my-3 ml-3 w-2/12 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                >
                    <option value="all">
                        {{ $t("approvals.all") }}
                    </option>
                    <option value="resolved">
                        {{ $t("approvals.approved") }}
                    </option>
                    <option value="rejected">
                        {{ $t("approvals.rejected") }}
                    </option>
                </select>

                <!-- Activity table (small breakopoint and up) -->
                <div class="block">
                    <div class="mx-auto">
                        <div class="flex flex-col mt-2">
                            <div
                                class="align-middle min-w-full overflow-x-auto border-b overflow-x-scroll"
                            >
                                <table
                                    class="table-auto min-w-full divide-y divide-gray-200"
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                class="whitespace-nowrap pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                            >
                                                {{ $t("thedesk.companyName") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                            >
                                                {{ $t("thedesk.transfer_amt") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                            >
                                                {{ $t("thedesk.payment_date") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm font-medium text-gray-500 tracking-wider border-r"
                                            >
                                                {{ $t("thedesk.ref_no") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm font-medium text-gray-500 tracking-wider border-r"
                                            >
                                                {{ $t("thedesk.payer_acc") }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap pl-4 py-3 bg-gray-50 text-left text-sm font-medium text-gray-500 tracking-wider border-r"
                                            >
                                                {{
                                                    $t("thedesk.resolved_date")
                                                }}
                                            </th>
                                            <th
                                                class="whitespace-nowrap px-6 py-3 bg-gray-50 text-left text-sm font-medium text-gray-500 tracking-wider"
                                            >
                                                {{ $t("approvals.status") }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        class="bg-white divide-y divide-gray-200"
                                    >
                                        <tr
                                            class="bg-white text-left font-medium text-tiny"
                                            v-for="(record, index) in this
                                                .resolvedList.list"
                                            :key="index"
                                        >
                                            <td
                                                class="border-r whitespace-nowrap pl-4 py-3"
                                            >
                                                {{ record.enterprise }}
                                            </td>
                                            <td class="border-r pl-4">
                                                {{ record.credit_amt }}
                                            </td>
                                            <td class="border-r pl-4">
                                                {{ record.payment_time }}
                                            </td>
                                            <td class="border-r pl-4">
                                                {{ record.reference_no }}
                                            </td>
                                            <td class="border-r pl-4">
                                                {{ record.payer_acc }}
                                            </td>
                                            <td class="border-r pl-4">
                                                {{ record.resolve_time }}
                                            </td>
                                            <td
                                                class="whitespace-nowrap text-center"
                                            >
                                                <span
                                                    class="relative mx-2 lg:mx-0 z-0 inline-flex rounded-md"
                                                >
                                                    <span
                                                        v-if="
                                                            record.state === 1
                                                        "
                                                        class="px-2 my-1 inline-flex items-center text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                                                    >
                                                        {{
                                                            $t(
                                                                "approvals.approved"
                                                            )
                                                        }}
                                                    </span>
                                                    <span
                                                        v-if="
                                                            record.state === 2
                                                        "
                                                        class="px-2 my-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                                                    >
                                                        {{
                                                            $t(
                                                                "approvals.rejected"
                                                            )
                                                        }}
                                                    </span>
                                                    <button
                                                        @click="
                                                            checkMessage(
                                                                index,
                                                                'resolved',
                                                                record.approval_id
                                                            )
                                                        "
                                                        type="button"
                                                        :class="{
                                                            'rounded-r-md': !record.attachment
                                                        }"
                                                        class="relative my-1 inline-flex items-center px-2 ml-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                    >
                                                        <svg
                                                            class="h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                                            />
                                                        </svg>
                                                    </button>
                                                    <button
                                                        @click="
                                                            getAdvice(
                                                                record.approval_id,
                                                                record.enterprise_id
                                                            )
                                                        "
                                                        v-if="record.attachment"
                                                        type="button"
                                                        class="-ml-px relative inline-flex items-center px-2 my-1 border rounded-r-md border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                                    >
                                                        Check receipt
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>

                                        <!-- More items... -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="this.resolvedList.total_page >= 1"
                        class="mt-8 flex flex-wrap justify-center items-center"
                    >
                        <button
                            @click="
                                currentPage2 > 1
                                    ? [
                                          (currentPage2 -= 1),
                                          this.getResolvedList(
                                              this.recordsFilter
                                          )
                                      ]
                                    : ''
                            "
                            type="button"
                            class="px-6 py-1 border rounded-md"
                        >
                            {{ $t("button.prev_pg") }}
                        </button>
                        <span class="mx-4">
                            {{ $t("page1") }} {{ currentPage2 }}/{{
                                this.resolvedList.total_page
                            }}
                            {{ $t("page2") }}
                        </span>
                        <button
                            @click="
                                currentPage2 < this.resolvedList.total_page
                                    ? [
                                          (currentPage2 += 1),
                                          this.getResolvedList(
                                              this.recordsFilter
                                          )
                                      ]
                                    : ''
                            "
                            type="button"
                            class="px-6 py-1 border rounded-md"
                        >
                            {{ $t("button.next_pg") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="toggleModal"
            class="fixed inset-0 flex justify-center items-center"
        >
            <div class="relative mx-auto w-11/12 lg:w-1/3">
                <div class="bg-white rounded-xl shadow-xl p-8 pb-20">
                    <div
                        class="flex -mx-8 -mt-8 px-4 py-4 bg-cyan-900 text-left px-3 rounded-xl"
                    >
                        <button
                            @click="toggleModal = false"
                            class="text-white text-2xl z-10"
                        >
                            ×
                        </button>
                        <label
                            for="remark"
                            class="-mx-4 mt-1 w-full text-center text-lg font-bold text-white"
                        >
                            Remark:
                        </label>
                    </div>
                    <div class="font-medium text-lg pt-8">
                        <textarea
                            name="remark"
                            id="remark"
                            ref="remark"
                            :value="remark_msg"
                            @keyup="remark_msg = $event.target.value"
                            class="mt-1 block border w-full border-gray-300 rounded-md shadow-sm pt-2 pb-8 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                        />
                    </div>
                </div>
                <button
                    @click="updateRemark(this.remark_msg, this.remark_id)"
                    class="absolute bottom-0 mb-4 mr-5 right-0 px-2 py-1 border border-black rounded-md"
                >
                    Save
                </button>
            </div>
        </div>
        <div
            v-if="toggleProof"
            class="fixed inset-0 z-10 flex justify-center items-center"
        >
            <div class="relative mx-auto w-auto min-w-1/3">
                <div class="bg-gray-200 rounded-xl shadow-xl p-8">
                    <div
                        class="flex -mx-8 -mt-8 px-4 py-4 text-left px-3 rounded-xl"
                    >
                        <button
                            @click="toggleProof = false"
                            class=" text-2xl z-10 focus:outline-none"
                        >
                            ×
                        </button>
                    </div>
                    <img
                        class="object-cover w-side desktop:w-modal h-auto"
                        :src="paymentProof"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TransactionApprovals",
    emits: ["updatePending"],
    data() {
        return {
            recordsFilter: "all",
            currentPage: 1,
            currentPage2: 1,
            toggleModal: false,
            toggleProof: false,
            messageIndex: -1,
            remark_id: -1,
            remark_msg: "",
            paymentProof: "",
            pendingList: {},
            resolvedList: {}
        }
    },
    mounted() {
        this.getPendingList()
        this.getResolvedList(this.recordsFilter)
    },
    methods: {
        async getPendingList() {
            const token = await this.$auth.getTokenSilently()
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/workspace/credit/pending/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&page=${this.currentPage}`
            this.$axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(response => {
                    this.pendingList = response.data.Data
                })
        },
        async getResolvedList(type) {
            const token = await this.$auth.getTokenSilently()
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/workspace/credit/resolved/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&type=${type}&page=${this.currentPage2}`
            this.$axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(response => {
                    this.resolvedList = response.data.Data
                })
        },
        async handleApprovals(pendingID, decision) {
            const token = await this.$auth.getTokenSilently()
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/workspace/credit/${decision}?lang=${this.$i18n.locale}`
            await this.$axios
                .post(
                    url,
                    {
                        user_id: this.$auth.user.value.sub,
                        id: pendingID
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    }
                )
                .then(() => {
                    this.getPendingList()
                    this.getResolvedList()
                })
        },
        updateFilter(i) {
            this.recordsFilter = i
            this.currentPage2 = 1
            this.getResolvedList(this.recordsFilter)
        },
        checkMessage(index, type, id) {
            if (type === "pending") {
                this.remark_msg = this.pendingList.list[index].ws_remark
            }
            if (type === "resolved") {
                this.remark_msg = this.resolvedList.list[index].ws_remark
            }
            this.remark_id = id
            this.messageIndex = index
            this.toggleModal = true
        },
        async getAdvice(id, enterprise_id) {
            const token = await this.$auth.getTokenSilently()
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/credit/transfer/attachment?user_id=${this.$auth.user.value.sub}&id=${id}&lang=${this.$i18n.locale}&enterprise_id=${enterprise_id}`
            this.$axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(res => {
                    if (
                        res.data.Data.url.includes(
                            "response-content-type=image"
                        )
                    ) {
                        this.paymentProof = res.data.Data.url
                        setTimeout(() => {
                            this.toggleProof = true
                        }, 500)
                    } else window.open(res.data.Data.url, "_blank")
                })
        },
        async updateRemark(msg, id) {
            if (this.remark_msg.length < 256) {
                const token = await this.$auth.getTokenSilently()
                const data = {
                    user_id: this.$auth.user.value.sub,
                    id: id,
                    ws_remark: msg
                }
                const url =
                    process.env.VUE_APP_API_BASE_URL +
                    `/workspace/credit/remark?lang=${this.$i18n.locale}`
                await this.$axios
                    .put(url, data, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    })
                    .then(() => {
                        this.getPendingList()
                        this.getResolvedList()
                        this.toggleModal = false
                    })
            } else alert("Your remark exceeds the maximum characters limit.")
        },
        date() {
            return new Date().toLocaleString()
        }
    }
}
</script>
